<!--  登录   -->
<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div v-if="steps===1" class="first">
      <div class="radioType">
        <p class="radioType_p" :class="type===0?'active' : ''" @click="choiceType(0)">
          <img v-show="type!==0" src="@/static/forget/phone2.png" alt="">
          <img v-show="type===0" src="@/static/forget/phone_red2.png" alt="">
          <span> 手机号</span>
          <span v-show="type===0" class="line"></span>
        </p>
        <p class="radioType_p" :class="type===1?'active' : ''" @click="choiceType(1)">
          <img v-show="type!==1" src="@/static/forget/user2.png" alt="">
          <img v-show="type===1" src="@/static/forget/user_red2.png" alt="">
          <span> 用户名</span>
          <span v-show="type===1" class="line"></span>
        </p>
        <p class="radioType_p" :class="type===2?'active' : ''" @click="choiceType(2)">
          <img v-show="type!==2" src="@/static/forget/email2.png" alt="">
          <img v-show="type===2" src="@/static/forget/email_red2.png" alt="">
          <span> 邮箱</span>
          <span v-show="type===2" class="line"></span>
        </p>
      </div>
      <div class="inp-all">
        <input v-if="type===0" v-model.trim="account" class="bor_red" placeholder="请输入您账号绑定的手机号">
        <input v-else-if="type===1" v-model.trim="account" class="bor_red" placeholder="请输入您账号的用户名">
        <input v-else v-model.trim="account" class="bor_red" placeholder="请输入您账号绑定的邮箱">
        <span v-show="account" @click="eliminate">
          <img src="@/static/forget/close.png" alt="">
        </span>
      </div>
      <p :style="isExists!=1?'visibility : visible':''" class="ps">{{ text }}</p>
      <div>
        <span class="next_btn" @click="next">下一步</span>
      </div>
      <p class="representations">以上都无法找回<span @click="appeal"> 人工申诉</span></p>
    </div>
    <div v-else-if="steps===2" class="second">
      <div class="tab-type" @click="phoneProving">
        <div class="tab-type-title">
          <img src="@/static/forget/phone_red2.png" alt="">
          <span>手机验证</span>
        </div>
        <div class="tab-type-text">
          通过{{ mobile }}接收验证码
        </div>
      </div>
      <div class="tab-type" @click="mailboxProving">
        <div class="tab-type-title">
          <img src="@/static/forget/email_red2.png" alt="">
          <span>邮箱验证</span>
        </div>
        <div class="tab-type-text">
          通过{{ email }}接收验证码
        </div>
      </div>
      <p class="representations">以上都无法找回<span @click="appeal"> 人工申诉</span></p>
    </div>
    <div v-else-if="steps===3" class="third">
      <div class="verify-text">为了保护账号安全，需要验证{{ textType }}的有效性</div>
      <div class="verify-text verify-grey" style="font-weight: 400;">点击以下按钮，将会发送验证码到您账号绑定的{{ textType }}</div>
      <div class="verify-text">{{ authType==1?mobile:email }}</div>
      <div>
        <span class="next_btn" @click="sendCode(authType)">发送验证码</span>
      </div>
      <p v-if="coexist" class="coexistP" @click="switchMode">切换至使用{{ authType==1?'邮箱':'手机' }}验证</p>
      <p v-if="authType==1" class="representations">该绑定手机号不可接收短信，<span @click="appeal"> 人工申诉</span></p>
      <p v-if="authType==2" class="representations">该绑定邮箱不可接收邮件，<span @click="appeal"> 人工申诉</span></p>
    </div>
    <div v-else-if="steps===4" class="fourth">
      <div class="verify-grey">验证码已发送到{{ textType }} <span>{{ authType==1?mobile:email }}</span></div>
      <div class="enterCode">
        <input v-model="code" class="bor_red" placeholder="请输入验证码">
        <span v-show="iscode" style="cursor: pointer;" class="countDown" @click="getcode">重新获取</span>
        <span v-show="againcode" class="countDown">{{ code_text }}</span>
      </div>
      <p :style="code_msg?'visibility : visible':''" class="ps">{{ code_msg }}</p>
      <div>
        <span class="next_btn" @click="sureCode">确定</span>
      </div>
      <p v-if="authType==1" class="representations">该绑定手机号不可接收短信，<span @click="appeal"> 人工申诉</span></p>
      <p v-if="authType==2" class="representations">该绑定邮箱不可接收邮件，<span @click="appeal"> 人工申诉</span></p>
    </div>
    <div v-else-if="steps===5" class="fifth">
      <div class="rule">密码为6-16个字符，不能为纯数字</div>
      <div class="currency">
        <input v-show="is_see" v-model="newpwd" type="password" class="bor_red" placeholder="请输入新密码">
        <input v-show="!is_see" v-model="newpwd" type="text" class="bor_red" placeholder="请输入新密码">
        <span class="eye">
          <img v-show="is_see" src="https://passport.zhulong.com/img/loginimg/bukejian.png" @click="is_see = false">
          <img v-show="!is_see" src="https://passport.zhulong.com//img/loginimg/kejian.png" @click="is_see = true">
        </span>
      </div>
      <div class="currency" :style="text?'marginBottom: 0px':''">
        <input v-show="is_see2" v-model="reconfirm_newpwd" type="password" class="bor_red" placeholder="请再次确认新密码">
        <input v-show="!is_see2" v-model="reconfirm_newpwd" type="text" class="bor_red" placeholder="请再次确认新密码">
        <span class="eye">
          <img v-show="is_see2" src="https://passport.zhulong.com/img/loginimg/bukejian.png" @click="is_see2 = false">
          <img v-show="!is_see2" src="https://passport.zhulong.com//img/loginimg/kejian.png" @click="is_see2 = true">
        </span>
      </div>
      <div :style="alter_text?'visibility : visible':''" class="ps" style="width: 100%">{{ alter_text }}</div>
      <span class="next_btn" @click="alter">确认更改</span>
    </div>
    <div v-else-if="steps===6" class="sixth">
      <div class="successP">
        <img src="@/static/forget/success.png" alt="">
        <div>
          <p>恭喜您，重置密码已成功！</p>
          <p>({{ time_text }}s)后自动返回主页</p>
        </div>
      </div>
      <div class="goLogin" @click="toLogin">去登录 >></div>
    </div>
    <div v-else>
      <div class="special">
        <p>非常抱歉，您的账号暂无任何绑定关系</p>
        <p>请通过<span>人工申述</span>，找回密码</p>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { Toast } from 'vant'
import { findAccount, sendMobile, sendEmail, vertifyMobile, vertifyEmail, updatePwd, pageInit } from '@/api/login'
import tool from '@/utils/tools'

export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '确认账号',
      steps: 1, // 进行到哪一步
      type: 0,
      text: '',
      account: '',
      uid: '',
      isExists: 1, // 账号/手机号/邮箱是否存在  1 存在  0 不存在
      mobile: '',
      email: '',
      is_mobile: '', // 1 已绑定手机 0未绑定
      is_email: '', // 1 已绑定邮箱 0 未绑定邮箱
      coexist: false, // 手机号/邮箱都有的情况 true
      textType: '',
      authType: '', // 验证类型 1 手机号验证 2 邮箱验证
      code: '',
      code_text: '60s', // 验证码重发倒计时
      s: 60,
      iscode: false,
      againcode: true,
      code_msg: '',

      newpwd: '',
      reconfirm_newpwd: '',
      alter_text: '',
      is_see: true,
      is_see2: true,

      timer2: '',
      ss: 3,
      time_text: 3
    }
  },
  created() {

  },
  mounted() {
    this.pageInit()
  },
  methods: {
    // 切换验证方法
    choiceType(val) {
      this.account = ''
      if (val === 0) {
        this.type = 0
      } else if (val === 1) {
        this.type = 1
      } else {
        this.type = 2
      }
    },
    // 清空输入框
    eliminate() {
      this.account = ''
    },
    // 下一步
    next() {
      const info = {
        type: this.type,
        account: this.account
      }
      findAccount(info).then(res => {
        if (res.errNo !== 0) {
          this.isExists = ''
          this.text = res.msg
          return
        } else {
          var data = res.result
          this.isExists = data.isExists
          if (this.isExists === 1) {
            this.uid = data.uid
            this.mobile = data.mobile
            this.email = data.email
            this.is_mobile = data.is_mobile
            this.is_email = data.is_email
            this.text = ''
            this.steps = 2
            this.title_msg = '安全验证'
          } else {
            this.text = '该账号不存在，请确认后再输入'
          }
        }
      }).then(() => {
        // 如果账号存在
        if (this.isExists === 1) {
          switch (this.type) {
            case 0:
              this.steps = 3
              this.authType = 1
              this.textType = '手机'
              break
            case 2:
              this.steps = 3
              this.authType = 2
              this.textType = '邮箱'
              break

            default:
              if (this.is_mobile === 1 && this.is_email === 1) {
                this.coexist = true
                this.steps = 2
              } else if (this.is_mobile !== 1 && this.is_email !== 1) {
                this.coexist = false
                this.steps = 10
              } else {
                this.steps = 3
                if (this.is_mobile === 1) {
                  this.authType = 1
                  this.textType = '手机'
                } else {
                  this.authType = 2
                  this.textType = '邮箱'
                }
              }
              break
          }
        }
      })
    },
    // 选择手机验证
    phoneProving() {
      this.steps = 3
      this.authType = 1
      this.textType = '手机'
    },
    // 选择邮箱验证
    mailboxProving() {
      this.steps = 3
      this.authType = 2
      this.textType = '邮箱'
    },
    // 邮箱/手机切换
    switchMode() {
      if (this.authType == 1) {
        this.authType = 2
        this.textType = '邮箱'
      } else {
        this.authType = 1
        this.textType = '手机'
      }
    },
    // 发送验证码
    sendCode() {
      this.steps = 4
      this.iscode = false
      this.againcode = true
      if (this.authType == 1) {
        this.sendMobile()
      } else {
        this.sendEmail()
      }
    },
    // 发送手机验证码
    sendMobile(lable) {
      sendMobile({ uid: this.uid }).then(res => {
        // Toast.success(res.msg)
        if (lable !== 'retransmission') {
          this.gettime()
        }
      })
    },
    // 发送邮箱验证码
    sendEmail(lable) {
      sendEmail({ uid: this.uid }).then(res => {
        if (lable !== 'retransmission') {
          this.gettime()
        }
      })
    },
    // 重发验证码
    getcode() {
      this.iscode = false
      this.againcode = true
      this.gettime()
      if (this.authType == 1) {
        this.sendMobile('retransmission')
      } else {
        this.sendEmail('retransmission')
      }
    },
    // 验证码验证
    sureCode() {
      if (this.code == '') {
        this.code_msg = '请输入验证码'
        // Toast.fail('请输入验证码')
      } else {
        if (this.authType == 1) {
          this.vertifyMobile()
        } else {
          this.vertifyEmail()
        }
      }
    },
    // 验证手机验证码
    vertifyMobile() {
      const info = {
        uid: this.uid,
        code: this.code
      }
      vertifyMobile(info).then(res => {
        if (res.errNo === 0) {
          this.code_msg = ''
          this.steps = 5
          this.title_msg = '重置密码'
        } else {
          this.code_msg = res.msg
          // Toast.fail(res.msg)
        }
      })
    },
    // 验证邮箱验证码
    vertifyEmail() {
      const info = {
        uid: this.uid,
        code: this.code
      }
      vertifyEmail(info).then(res => {
        if (res.errNo === 0) {
          this.code_msg = ''
          this.steps = 5
          this.title_msg = '重置密码'
        } else {
          this.code_msg = res.msg
          // Toast.fail(res.msg)
        }
      })
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        this.code_text = this.s + 's'
        if (this.s === 0) {
          this.iscode = true
          this.againcode = false
          clearInterval(this.timer)
          this.s = 60
          this.code_text = '60s'
        }
      }, 1000)
    },
    // 确认更改
    alter() {
      if (this.newpwd === '' || this.reconfirm_newpwd === '') {
        this.alter_text = '请输入新密码'
        return this.alter_text
      }
      if (this.newpwd !== this.reconfirm_newpwd) {
        this.alter_text = '密码不一致，请重新输入'
        return this.alter_text
      }
      var reg = /^[0-9]*$/// 正则表达式
      var regF = new RegExp(reg)
      // regF.test(this.newpwd)
      if (regF.test(this.newpwd)) {
        this.alter_text = '6-16位字符，不能是纯数字'
        return this.alter_text
      }
      if (this.newpwd.length < 6 || this.newpwd.length > 16) {
        this.alter_text = '请输入6-12位字符'
        return this.alter_text
      }
      const info = {
        uid: this.uid,
        newpwd: this.newpwd
      }
      updatePwd(info).then(res => {
        if (res.errNo === 0) {
          this.alter_text = ''
          this.steps = 6
          this.goZl()
        } else {
          this.alter_text = res.msg
        }
      })
    },
    // 去首页
    goZl() {
      // setTimeout(() => {
      //   window.location.href = 'https://www.zhulong.com'
      // }, 3000)
      this.timer2 = setInterval(() => {
        // 某些定时器操作
        this.ss--
        this.time_text = this.ss
        if (this.ss === 0) {
          clearInterval(this.timer)
          this.toLogin()
          this.ss = 3
        }
      }, 1000)
    },
    // 去登录
    toLogin() {
      const url = window.location.origin + '/ucenter/login'
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('finish')
        } else {
          this.$jsbridge.callHandler(
            'vue_backApp'
            , 'finish'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        // 注考帮h5
        window.location.href = url
      }
    },
    // 获取客服链接
    pageInit() {
      pageInit().then(res => {
        if (res.errNo === 0) {
          this.services_link = res.result.services_link
        }
      })
    },
    // 人工申诉
    appeal() {
      window.location.href = this.services_link
    }
  }
}
</script>

<style lang='scss' scoped>
.first {
  .radioType {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 30px auto 30px;
    text-align: center;
    p {
      width: 28%;
      // line-height: 40px;
      font-size: 16px;
      color: #999999;
      font-weight: 500;
      cursor: pointer;
    }
    .active {
      color: #ee2e2e;
    }
    .radioType_p {
      img {
        vertical-align: middle;
        width: 32px;
      }
      span {
        vertical-align: middle;
        font-size: 32px;
      }
      .line {
        display: block;
        width: 60%;
        border: 1px solid #ee2e2e;
        margin: 10px auto 0;
      }
    }
  }
  .inp-all {
    width: 80%;
    min-width: 680px;
    height: 100px;
    margin: 0 auto;
    border: 2px solid rgba(153, 153, 153, 1);
    position: relative;
    .bor_red {
      outline: none;
      width: calc(100% - 70px);
      height: 80px;
      padding: 10px 20px;
      font-size: 28px;
      color: #333;
    }
    span {
      position: absolute;
      display: inline-block;
      top: 50%;
      margin-top: -16px;
      right: 5px;
    }
  }
  .next_btn {
    width: 80%;
    min-width: 680px;
  }
}
.second {
  .tab-type {
    width: 80%;
    height: 110px;
    padding: 25px 30px;
    margin: 40px auto 0;
    border: 1px solid rgba(153, 153, 153, 1);
    .tab-type-title {
      height: 50%;
      line-height: 60px;
      font-weight: 600;
      img {
        width: 36px;
        vertical-align: middle;
        margin-right: 10px;
      }
      span {
        vertical-align: middle;
        color: #ee2e2e;
        padding-top: 3px;
        font-size: 32px;
      }
    }
    .tab-type-text {
      height: 50%;
      font-size: 32px;
      line-height: 60px;
    }
  }
}
.third {
  width: 80%;
  min-width: 680px;
  margin: 60px auto 0;
  font-size: 25px;
  .verify-text {
    margin-bottom: 30px;
    font-size: 28px;
    color: #666666;
    font-weight: 600;
  }
  .next_btn {
    width: 100%;
  }
  .coexistP {
    text-align: center;
    margin: 30px 0 0 0;
    color: #ee2e2e;
    font-weight: 600;
    font-size: 28px;
  }
}
.fourth {
  width: 80%;
  min-width: 680px;
  margin: 60px auto 0;
  font-size: 28px;
  .enterCode {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    height: 96px;
    .bor_red {
      outline: none;
      -webkit-appearance: none; /*去除系统默认的样式*/
      width: 60%;
      height: 92px;
      padding-left: 10px;
      border: 2px solid #dcdcdc;
      line-height: 92px;
      font-size: 28px;
      color: #333;
    }
    .countDown {
      display: inline-block;
      width: 35%;
      text-align: center;
      height: 92px;
      line-height: 92px;
      border: 2px solid #dcdcdc;
      color: #999;
      font-size: 28px;
    }
  }
  .next_btn {
    width: 100%;
  }
}
.fifth {
  width: 80%;
  min-width: 680px;
  margin: 60px auto 0;
  .rule {
    font-size: 28px;
    color: #666666;
    text-align: center;
  }
  .currency {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 40px;
    height: 96px;
    .bor_red {
      outline: none;
      -webkit-appearance: none; /*去除系统默认的样式*/
      width: 100%;
      height: 92px;
      padding-left: 10px;
      border: 2px solid #dcdcdc;
      line-height: 92px;
      font-size: 28px;
      color: #333;
    }
    .eye {
      position: absolute;
      right: 0;
      margin: 36px 20px 0 0;
    }
  }
  .next_btn {
    width: 100%;
  }
}
.sixth {
  .successP {
    width: 60%;
    display: flex;
    align-items: center;
    margin: 60px auto 0;
    img {
      width: 70px;
      height: 70px;
      display: block;
      margin-right: 20px;
      flex-shrink: 0;
    }
    p {
      font-family: PingFangSC-Medium;
      font-size: 28px;
      color: #ee2e2e;
      letter-spacing: 0;
      font-weight: 600;
      text-align: center;
    }
  }
  .goLogin {
    width: 60%;
    margin: 30px auto 0;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 32px;
    color: #666666;
    letter-spacing: 0;
    font-weight: 600;
  }
}

.special {
  width: 80%;
  margin: 60px auto 0;
  p {
    font-family: PingFang-SC-Medium;
    font-size: 28px;
    line-height: 46px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    span {
      color: #ee2e2e;
    }
  }
}

// 以下为公共样式
.verify-grey {
  color: #666666;
}
.representations {
  width: 80%;
  text-align: center;
  margin: 30px auto 0;
  font-size: 24px;
  span {
    color: #ee2e2e;
    cursor: pointer;
    font-weight: 600;
  }
}
.next_btn {
  width: 80%;
  height: 100px;
  display: block;
  background: #ee2e2e;
  text-align: center;
  color: #fff;
  font-size: 32px;
  line-height: 100px;
  margin: 0 auto;
  cursor: pointer;
}

.ps {
  width: 80%;
  min-width: 680px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  color: #ee2e2e;
  visibility: hidden;
  font-size: 28px;
}
input::-webkit-input-placeholder {
  /* placeholder颜色 */
  color: #999;
  /* placeholder字体大小 */
  font-size: 28px !important;
}
</style>
